.hero {
  background: #0b0933;
  color: #fff;
  background-image: url("/images/researchHeroWide.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


  h1 {
    color: #fff;
    letter-spacing: -0.03em;
    font-weight: 500;
    font-size: 70px;
    line-height: normal;
    max-width: 1000px;
  }

  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  li {
    font-size: 1.4em;
    line-height: 1.4em;
    list-style-type: square;
    padding-left: 1.2em;
    margin-bottom: 0.8em;
  }
}

.heroContent {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  min-height:  90svh;
}

.card {
  background: hsl(0, 0%, 100%);
  box-shadow: 0px 4px 20px hsla(0, 0%, 0%, 0.08);
  border-radius: 30px;
  padding: 39px 34px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 8px;
  height: 36px;

  svg {
    width: auto;
    height: 100%;
  }
}

.heroImage {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50%;
  z-index: 0;
  max-height: 380px;
  aspect-ratio: 600/449;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}


.ctaButton {
  background: hsl(221, 79%, 34%);
  box-shadow: 0px 4px 20px hsla(0, 0%, 0%, 0.08);
  border-radius: 1000em;
  padding: 18px 31px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: hsl(0, 0%, 100%);
  outline: 0;
  border: none;
  transition: 300ms;
  align-self: center;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background: hsl(220, 83%, 16%);
  }
}

@media (max-width: 768px) {
  .heroImage {
    position: relative;
    width: 100%;
    max-height: unset;
    max-width: unset;
  }
}