.card {
  background: hsl(0, 0%, 100%);
  box-shadow: 0px 4px 20px hsla(0, 0%, 0%, 0.08);
  border-radius: 30px;
  padding: 39px 34px;
}

.icon {
  font-size: 64px;
  line-height: 77px;
  font-family: sans-serif;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: hsl(0, 6%, 31%);
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.01em;
  color: hsl(0, 8%, 61%);
}