.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.7);
  z-index: 1;
  transition: 200ms;

  animation: backgroundAppear 200ms ease-in-out;
}

.content {
  background: hsl(0, 0%, 100%);
  box-shadow: 0 2px 20px hsla(0, 0%, 0%, 0.9);
  border: 10px solid hsla(246, 71%, 8%, 0.9);
  border-radius: 28px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  color: hsl(0, 0%, 20%);
  position: relative;
  animation: contentSlide 250ms ease;
  max-height: 100%;

  max-width: 580px;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border-radius: 8px;
  padding: 16px;
  overflow: auto;
  min-width: 940px;

  h4 {
    font-size: 1.1em;
    margin: 0;
    padding: 0;
  }

}

.circle {
  border-radius: 50%;
  background: hsl(0, 0%, 100%);
  position: absolute;
  top: 0;
  left: 50%;
  align-self: center;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  transform: translate(-50%, -50%);

  svg {
    height: 50%;
    width: auto;
  }
}

.circle + .body {
  padding-top: 44px;
}

@media only screen and (max-width: 1024px) {
  .body{
    min-width: 750px;
  }
}

@media only screen and (max-width: 600px) {
  .body{
    min-width: 390px;
  }

  .container {
    align-items: flex-start;
  }

  .content {
    border: none;
    border-radius: 8px;
  }
}




@keyframes backgroundAppear {
  from {
    opacity: 0;
  }
}

@keyframes contentSlide {
  from {
    transform: translate(0, -80px);
    opacity: 0.2;
  }
}
