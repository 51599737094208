.container {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.529);
  color: hsla(200, 50%, 68%, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  transition: all 300ms;

  &.focused {
    border-bottom: 1px solid hsl(0, 0%, 100%);

  }
  &.unfocused {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
  }
}

.input {
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 12px 8px;
  color: hsl(0, 0%, 100%);
  font-size: 1.2rem;
  line-height: 120%;
  font-family: var(--font);


  &:focus-within{
    background-color: hsla(0, 0%, 0%, 0.15);
    color: hsl(195, 69%, 90%);
  }

  &::placeholder {
    color: hsla(200, 72%, 76%, 0.6);
    opacity: 1; 
  }
}