.content {
  width: 50%;

  h1 {
    font-family: var(--headings);
    line-height: 110%;
    font-size: 80px;
  }
}

.image {
  position: absolute;
  bottom: -40px;
  right: -40px;
  align-self: flex-end;
}

@media only screen and (max-width: 1800px) {
  .image {
    width: 760px;
    height: auto;
  }
}

@media only screen and (max-width: 1350px) {
  .image {
    width: 650px;
    height: auto;
  }
}

@media only screen and (max-width: 1090px) {
  .image {
    width: 500px;
    height: auto;
  }

  .content {
    width: 70%;
  }
}

.imageContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  height: 740px;
  width: 979px;
  
}

@media (max-width: 980px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: unset;
  }
  .image {
    width: 95%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    object-fit: cover;
    object-position: 0% 0%;
    position: relative;
    bottom: unset;
    right: unset;
    align-self: unset;
    width: 100%;
    height: auto;
  }

  .imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    position: unset;
    height: auto;
    margin-top: 50px;
    grid-column: content;
  }
}

@media (max-width: 768px) {
  .section {
    background-position: 0% 0%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #2d2852;
  }

  .content {
    width: 85%;
    // height: 810px;

    h1 {
      font-size: 70px;
    }
    p {
      font-size: 1rem;
    }
  }

  .image {
    width: 400px;
  }
}

.largeCTA{
  align-self: flex-start;  
}



@media (max-width: 600px) {
  .largeCTA{
    align-self: unset;  
  }
  
}



@media (max-width: 425px) {
  .kudos {
    justify-content: center;
  }

  .content {
    width: 100%;

    h1 {
      font-size: 60px;
    }
    p {
      font-size: 1rem;
    }
  }

  .image {
    width: 95%;
    object-fit: cover;
    object-position: 0% 0%;
    height: 240px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}
