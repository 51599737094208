.header {
  font-weight: normal;
  font-size: 1.5em;
  font-family: var(--font);
  line-height: 1.4;
  max-width: 600px;
  padding: 0 20px;
  color: hsl(0, 0%, 20%);
  margin: 20px auto 1em auto;

  font-size: 20px;
  color: hsl(196, 12%, 63%);
  text-align: center;
}

.positivecustomers {
  margin-top: 6em;
  margin-bottom: 9em;
  justify-items: center;

  &:hover .person {
    transform: scale(0.86) translateY(-8px);
    opacity: 0.3;

    &:hover {
      transform: scale(1.04) translateY(-8px);
      opacity: 1;
    }
  }
}

.person {
  transition: 300ms; 
  flex-grow: 1;
  cursor: pointer;
  position: relative;
}

.person2 {
  width: 100%;
  padding-top: 33px;
}

.background {
  position: relative;
  height: 65px;
  background: linear-gradient(to bottom right, #047BCA 0%, #047A2A 100%);
  border-radius: 20px 20px 0 0;

  &:nth-child(even) {
    background: linear-gradient(to bottom right, #1C63B6 0%, #914CC0 100%);
  }
}

.play {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 18px;
  height: 18px;
  --fill-opacity: 0.4;
  color: #fff
}

.text {
   background: #011823;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   text-align: right;
   padding: 6px 8px 12px;
   gap: 2px;
   border-radius: 0 0 20px 20px;
}
.name {
  color: #fff;
  font-size: 12px;
}
.line {
  font-size: 8px;
  background: #068EE0;
  font-weight: bold;
  background: linear-gradient(to right, #109ff7 0%, #0CBDC2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3em;
  min-height: 1.3em;
}