.cost {
  background: #f1f1f6;
  padding-top: 32px;
  padding-bottom: 32px;
}

.costContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.h2 {
  font-style: normal;
  letter-spacing: -0.03em;
  text-align: left;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: hsl(0, 6%, 31%);
}

.ctaButton {
  background: hsl(221, 79%, 34%);
  box-shadow: 0px 4px 20px hsla(0, 0%, 0%, 0.08);
  border-radius: 1000em;
  padding: 18px 31px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: hsl(0, 0%, 100%);
  outline: 0;
  border: none;
  transition: 300ms;
  align-self: center;
  cursor: pointer;

  &:hover {
    background: hsl(220, 83%, 16%);
  }
}