.price {
  font-family: var(--font);
  font-style: normal;
  font-weight: 700;
  font-size: 92px;
  line-height: 110px;
  text-align: center;
  letter-spacing: -0.04em;
  color: hsl(0, 6%, 31%);

  sup{
    font-size: .4em;
    font-weight: normal;
    color: hsl(226, 22%, 73%);
  }
  
}

