.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 39px 34px;
  position: relative;
  gap: 30px;
  text-align: center;
  overflow: hidden;
}

.icon {
  font-size: 44px;
  line-height: normal;
  font-family: sans-serif;
}

.text {
  font-size: 20px;
  line-height: normal;
  letter-spacing: -0.025em;
  color: hsl(0, 6%, 31%);
}