.background {
  background: #071921;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff;
}

.content {
  display: flex;
  flex-direction: column;
  color: #fff;
  flex-basis: 0;
  flex-grow: 1;
  gap: 20px;

  h2 {
    font-weight: 700;
    font-size: 1.5em;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-basis: 0;
  flex-grow: 1;
}

.ctaButton {
  background: hsl(221, 79%, 34%);
  box-shadow: 0px 4px 20px hsla(0, 0%, 0%, 0.08);
  border-radius: 1000em;
  padding: 18px 31px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: hsl(0, 0%, 100%);
  outline: 0;
  border: none;
  transition: 300ms;
  align-self: center;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background: hsl(220, 83%, 16%);
  }
}